import {
  BulbOutlined,
  CloseOutlined,
  EditOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Dropdown, Input, Menu, Switch } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import React, { useEffect, useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Link } from "react-router-dom";
import { appName, LocalStorageKeys } from "../enum/enum";
import { useAppSelector } from "../store/reduxHooks";
import { clearLocalStorage } from "../util/localStorage.util";
interface IProps {
  handleSidebarTrigger: () => void;
  onClose: () => void;
  showDrawer: () => void;
  sidebarColllapse: boolean;
}

const Navbar = (props: IProps) => {
  const { switcher, themes } = useThemeSwitcher();
  const { user } = useAppSelector((state) => state.authReducer);
  const [darkTheme, setDarkTheme] = useState(false);

  const [showResponsiveSearch, setShowResponsiveSearch] =
    useState<boolean>(false);

  const handleResponsiveSearch = () => {
    setShowResponsiveSearch(true);
  };

  const handleResponsiveSearchClose = () => {
    setShowResponsiveSearch(false);
  };

  const signOutHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    clearLocalStorage();
    window.location.href = "/login";
  };

  const themeSwitchHandler = (value: boolean) => {
    switcher({ theme: value ? themes.dark : themes.light });
    localStorage.setItem(LocalStorageKeys.THEME, value ? "dark" : "light");
    setDarkTheme(value);
  };

  useEffect(() => {
    const theme = localStorage?.getItem(LocalStorageKeys.THEME);
    if (theme) {
      switcher({ theme: theme === "dark" ? themes.dark : themes.light });
    } else {
      switcher({ theme: "light" });
      localStorage.setItem(LocalStorageKeys.THEME, "light");
    }
    setDarkTheme(theme === "dark" ? true : false);
  }, [switcher, themes, setDarkTheme]);

  // const menuNotification = (
  //   <Menu className="notification__dropdown">
  //     <Menu.Item>
  //       <div className="notification__header d__flex align__items__center">
  //         <h4>Notification</h4>
  //         <Button type="link" className="clear__btn">
  //           Clear
  //         </Button>
  //       </div>
  //     </Menu.Item>

  //     <Menu.Item>
  //       <div className="notification__list">
  //         <Avatar size={40}>
  //           <img src={Profile} alt="" />
  //         </Avatar>
  //         <div className="comment__section">
  //           <span className="commentor__name">Erin Gonzales </span>
  //           <span className="comment__text">has comment on your board</span>
  //         </div>
  //         <span className="comment__time">7:57PM</span>
  //       </div>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <div className="notification__list">
  //         <Avatar size={40}>
  //           <img src={Profile} alt="" />
  //         </Avatar>
  //         <div className="comment__section">
  //           <span className="commentor__name">Erin Gonzales </span>
  //           <span className="comment__text">has comment on your board</span>
  //         </div>
  //         <span className="comment__time">7:57PM</span>
  //       </div>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <div className="notification__list">
  //         <Avatar size={40}>
  //           <img src={Profile} alt="" />
  //         </Avatar>
  //         <div className="comment__section">
  //           <span className="commentor__name">Erin Gonzales </span>
  //           <span className="comment__text">has comment on your board</span>
  //         </div>
  //         <span className="comment__time">7:57PM</span>
  //       </div>
  //     </Menu.Item>
  //   </Menu>
  // );

  // const menuLanguage = (
  //   <Menu>
  //     <Menu.Item>
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.antgroup.com"
  //       >
  //         English
  //       </a>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.aliyun.com"
  //       >
  //         Japanese
  //       </a>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.luohanacademy.com"
  //       >
  //         Korean
  //       </a>
  //     </Menu.Item>
  //   </Menu>
  // );

  const menuProfile = (
    <Menu className="profile__dropdown">
      <Menu.Item>
        <Link to="/profile">
          <div className="avatar_container profile__section d__flex">
            <Avatar size={40}>{user?.fullname?.charAt(0)}</Avatar>
            <div>
              <h4 className="user__name">{user?.fullname}</h4>
              <span className="work__department text__capitalize ">
                {user?.role}
              </span>
            </div>
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/profile">
          <span className="link__iocns">
            <EditOutlined />
          </span>
          Edit Profile
        </Link>
      </Menu.Item>
      {/* <Menu.Item>
        <Link
          to="#"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          <span className="link__iocns">
            <SearchOutlined />
          </span>
          Account Setting
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="#"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          <span className="link__iocns">
            <ShopOutlined />
          </span>
          Billing
        </Link>
      </Menu.Item> */}
      <Menu.Item>
        <Link
          to="#"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          <span className="link__iocns">
            <QuestionCircleOutlined />
          </span>
          Help Center
        </Link>
      </Menu.Item>
      <MenuItem>
        <span className="theme__switch__row">
          <BulbOutlined />
          Dark Mode
          <Switch checked={darkTheme} onChange={themeSwitchHandler} />
        </span>
      </MenuItem>
      <Menu.Item>
        <Link to="#" onClick={signOutHandler}>
          <span className="link__iocns">
            <LogoutOutlined />
          </span>
          Signout
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="page__header">
      <div
        className={`header__logo  ${
          props.sidebarColllapse ? "shrinked" : ""
        } desktop`}
      >
        <span>{appName}</span>
      </div>
      <div className="header__main">
        <div
          className={`responsive__search ${showResponsiveSearch ? "show" : ""}`}
        >
          <div className="search__wrapper">
            <Button>
              <SearchOutlined />
            </Button>
            <Input placeholder="Basic usage" />
          </div>
          <Button
            onClick={handleResponsiveSearchClose}
            className="btn__close__search"
          >
            <CloseOutlined />
          </Button>
        </div>

        <Button
          className="btn__menu__toggle"
          onClick={props.handleSidebarTrigger}
        >
          {props.sidebarColllapse ? (
            <MenuUnfoldOutlined />
          ) : (
            <MenuFoldOutlined />
          )}
        </Button>
        <Button
          onClick={props.showDrawer}
          className="btn__menu__toggle drawer__trigger"
        >
          <MenuUnfoldOutlined />
        </Button>
        {/* <div className="search__wrapper">
          <Button>
            <SearchOutlined />
          </Button>
          <Input placeholder="Basic usage" />
        </div> */}
        <ul className="navigation__right">
          <li className="display__lg">
            <Button
              className="btn__search__sm"
              onClick={handleResponsiveSearch}
            >
              <SearchOutlined />
            </Button>
          </li>
          {/* <li>
            <Dropdown
              overlay={menuNotification}
              placement="bottomRight"
              arrow
              trigger={["click"]}
            >
              <Button className="btn__notification">
                <BellOutlined />
                <Badge count={5} />
              </Button>
            </Dropdown>
          </li> */}
          <li className="avatar_container d__flex align__items__center">
            <Dropdown
              overlay={menuProfile}
              placement="bottomRight"
              arrow
              trigger={["click"]}
            >
              <Avatar size="large">{user?.fullname?.charAt(0)}</Avatar>
            </Dropdown>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
