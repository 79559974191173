import { getAuthToken } from "./../util/localStorage.util";
import axiosInstance from "../axios/axios";
import { IHttp } from "../interfaces/http.interface";
import {
  IFiscalYear,
  ILoginRequest,
  ILoginResponse,
  IUser,
} from "./../interfaces/auth.interface";

export const login = async (
  payload: ILoginRequest
): Promise<[IHttp<ILoginResponse>, any]> => {
  try {
    const response = await axiosInstance.post("/auth/login", payload);
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getProfile = async (): Promise<[IHttp<IUser>, any]> => {
  try {
    const response = await axiosInstance.get("/auth/me", {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getFiscalYear = async (): Promise<[IHttp<IFiscalYear>, any]> => {
  try {
    const response = await axiosInstance.get("/fiscal-year", {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const getResetLink = async (data: any): Promise<[any, any]> => {
  try {
    await axiosInstance.post("/auth/reset-email", data, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [true, null];
  } catch (error) {
    return [null, error];
  }
};
