export enum LocalStorageKeys {
  ACCESS_TOKEN = "accessToken",
  REFRESH_TOKEN = "refreshToken",
  THEME = "theme",
}

export const appName = "ITI";

export const planList = [
  { value: "standard", label: "Standard" },
  { value: "basic", label: "Basic" },
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
];
