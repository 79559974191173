import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "./hoc/ErrorBoundary";
import Routes from "./routes";
import { store } from "./store/store";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
// import "./assets/less/main-light.less";
import "./assets/less/main-dark.less";

function App() {
  const themes = {
    light: `${process.env.PUBLIC_URL}/main-light.min.css`,
    dark: `${process.env.PUBLIC_URL}/main-dark.min.css`,
  };
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Provider store={store}>
          <ThemeSwitcherProvider defaultTheme="light" themeMap={themes}>
            <Routes />
          </ThemeSwitcherProvider>
          {/* <Routes /> */}
        </Provider>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
