import {
  AccountBookOutlined,
  ContactsOutlined,
  DashboardOutlined,
  FileProtectOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import { NavLinks } from "../interfaces";

export const sideNavList: NavLinks[] = [
  { href: "/", icon: <DashboardOutlined />, title: "Dashboard" },
  {
    href: "/certificates",
    icon: <AccountBookOutlined />,
    title: "Certificates",
    children: [
      {
        href: "/certificates/create",
        icon: <SafetyCertificateOutlined />,
        title: "Create certificate",
      },
      {
        href: "/certificates/my-certificates",
        icon: <FileProtectOutlined />,
        title: "My Certificates",
      },
    ],
  },
  { href: "/policy-list", icon: <ContactsOutlined />, title: "Policy List" },
];
