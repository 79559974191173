import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import { Redirect, Switch } from "react-router";
import { mainRoutes } from "../routes/main.routes";
import {
  getFiscalYearAction,
  getProfileAction,
} from "../store/actions/auth/authActionCreators";
import { useAppDispatch } from "../store/reduxHooks";
import { isUserLoggedIn } from "../util/auth.util";
import { errorParser } from "../util/common.util";
import Header from "./header";
import Navdrawer from "./navdrawer";
import { openErrorNotification } from "./notification";
import Sidenav from "./sidenav";

const { Content } = Layout;

function MainLayout() {
  const [sidebarColllapse, setSidebarColllapse] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const dispatch = useAppDispatch();

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const hideDrawer = () => {
    setVisible(false);
  };

  const handleSidebarTrigger = () => {
    setSidebarColllapse(!sidebarColllapse);
  };

  useEffect(() => {
    if (isUserLoggedIn()) {
      dispatch(
        getProfileAction((err?: any) => {
          if (err) {
            openErrorNotification(errorParser(err), "Error");
          }
        })
      );
      dispatch(
        getFiscalYearAction((error?: any) => {
          if (error) {
            openErrorNotification(errorParser(error), "Error");
          }
        })
      );
    }
  }, [dispatch]);

  if (!isUserLoggedIn()) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <Header
        sidebarColllapse={sidebarColllapse}
        handleSidebarTrigger={handleSidebarTrigger}
        onClose={onClose}
        showDrawer={showDrawer}
      />
      <div className="sidenav-container">
        <Sidenav sidebarColllapse={sidebarColllapse} />
      </div>
      <Navdrawer onClose={onClose} visible={visible} hideDrawer={hideDrawer} />
      <Content
        className={`page__wrapper ${sidebarColllapse ? "expanded" : ""}`}
      >
        <Switch>
          {mainRoutes}
          <Redirect to="/pagenotfound" />
        </Switch>
      </Content>
    </>
  );
}

export default MainLayout;
